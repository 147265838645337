body {
  background-image: url('https://gw.alipayobjects.com/zos/rmsportal/TVYTbAXWheQpRcWDaDMu.svg');
  background-repeat: no-repeat;
  background-position: center 110px;
  background-size: 100%;
}


.login {
  text-align: center;
  width: 350px;
  margin: auto;
  padding-top: 200px;
}

//.loginForm{
//  width: 330px;
//  margin: auto;
//  padding-top: 200px;
//}

.ant-input-lg {
  font-size: 13px !important;
}

.ant-input-affix-wrapper > input.ant-input {
  padding-left: 6px !important;
}
@root-entry-name: default;