:root {
  --danger-color: #B2404C;
  --warning-color: #f2dede;
  --warning-hover-color: #e4b9b9;
  --success-color: #dff0d8;
  --success-hover-color: #c1e2b3;
}


// 当表格数据为空时,会撑高空白样式
.ant-empty-normal {
  margin: 140px 0 !important;
}

.warningRow {
  background-color: var(--warning-color);
}

.successRow {
  background-color: var(--success-color);
}

.dangerRow {
  background-color: var(--danger-color);
  color: lightblue;
}

tr.ant-table-row > td {
  font-size: 12px;
}

tbody tr.ant-table-row.dangerRow:hover > td {
  background-color: var(--danger-color);
}

tbody tr.ant-table-row.successRow:hover > td {
  background-color: var(--success-hover-color);
}

tbody tr.ant-table-row.warningRow:hover > td {
  background-color: var(--warning-hover-color);
}

//tr.ant-table-row:hover > td {
//  background-color: transparent !important;
//}

.leftTalk, .rightTalk {
  display: flex;
  width: 100%;

  .ant-avatar {
    min-width: 35px;
  }

  .content {
    max-width: 500px;
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    //align-items: center;
  }

}

.rightTalk {
  justify-content: flex-end;
}

.centerTalk {
  display: flex;
  width: 100%;
  justify-content: center;
  color: grey;
}
@root-entry-name: default;