html,
body {
  height: 100%;
  background-color: #f8f8f8;
}

#root,
#home-layout {
  width: 100%;
  height: 100%;
}

@root-entry-name: default;